import { lastDigestDate, lastExtranetDigestDate } from '@otello/helpers';
import { isAfter, parse } from 'date-fns';

export const getIsNeedBadge = (
  lastView: string,
  lastDigestDate: string,
): boolean =>
  isAfter(
    parse(lastDigestDate, 'dd.MM.yyyy', new Date()),
    parse(lastView, 'dd.MM.yyyy HH:mm', new Date()),
  );

export const getLastDigestDate = (isExtranet: boolean) =>
  isExtranet ? lastExtranetDigestDate : lastDigestDate;
