import { baseApi } from '@otello/store';

import { errorTransform } from '../../helpers/helpers';
import {
  HTTP_METHODS,
  HotelAllowPublishResponse,
  HotelPublishQueryParams,
  HotelPublishResponse,
  HotelListResponse,
  HotelListQueryParams,
  HotelBookingContactsQueryParams,
} from '../../types';
import type {
  HasUnpublishedRecordsResponse,
  HotelBankInfoResponse,
  HotelBankInfoSearchQueryParams,
  HotelCheckInRulesQueryParams,
  HotelContactsQueryParams,
  HotelInfoResponse,
  HotelLegalInfoQueryParams,
  HotelRelatedBranchesResponse,
  HotelRelatedBranchQueryParams,
  HotelSearchLegalInfoQueryParams,
  HotelSearchLegalInfoResponse,
  HotelUpdateRelatedBranchesQueryParams,
  LegalInfoChangesQueryParams,
  TransformedHotelListItem,
} from '../../types';

export const hotelsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getHotelInfo: builder.query<HotelInfoResponse, string>({
      query: (id) => ({
        url: `hotels/${id}/`,
        method: HTTP_METHODS.GET,
        credentials: 'include',
      }),
      providesTags: [{ type: 'Hotel', id: 'LIST' }],
    }),
    setHotelLegalInfo: builder.mutation<
      HotelInfoResponse['legal_info'],
      HotelLegalInfoQueryParams
    >({
      query: ({ hotelId, legalId, body, method }) => ({
        url: `hotels/${hotelId}/legal_info/${legalId ? `${legalId}/` : ''}`,
        method: method,
        body,
        credentials: 'include',
      }),
      onQueryStarted: async ({ hotelId }, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            hotelsApi.util.updateQueryData(
              'getHotelInfo',
              String(hotelId),
              (draft) => ({
                ...draft,
                legal_info: data,
              }),
            ),
          );
        } catch (e) {
          console.error(e);
        }
      },
      invalidatesTags: ['HotelAllowPublish'],
    }),

    setBookingContacts: builder.mutation<
      {
        booking_notification_contacts: HotelInfoResponse['booking_notification_contacts'];
      },
      HotelBookingContactsQueryParams
    >({
      query: ({ id, booking_notification_contacts }) => ({
        url: `hotels/${id}/booking_notification_contacts/`,
        method: HTTP_METHODS.PUT,
        credentials: 'include',
        body: { booking_notification_contacts },
      }),
      onQueryStarted: async ({ id }, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            hotelsApi.util.updateQueryData(
              'getHotelInfo',
              String(id),
              (draft) => {
                if (data) {
                  return {
                    ...draft,
                    booking_notification_contacts:
                      data.booking_notification_contacts,
                  };
                }

                return draft;
              },
            ),
          );
        } catch (e) {
          console.error(e);
        }
      },
      transformErrorResponse: ({ data, status }) =>
        errorTransform(data, status),
      invalidatesTags: ['HotelAllowPublish', 'HotelUnpublishedRecords'],
    }),

    setHotelContacts: builder.mutation<
      { hotel_contacts: HotelInfoResponse['hotel_contacts'] },
      HotelContactsQueryParams
    >({
      query: ({ id, hotel_contacts }) => ({
        url: `hotels/${id}/contacts/`,
        method: HTTP_METHODS.PATCH,
        credentials: 'include',
        body: { hotel_contacts },
      }),
      onQueryStarted: async ({ id }, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            hotelsApi.util.updateQueryData(
              'getHotelInfo',
              String(id),
              (draft) => ({
                ...draft,
                hotel_contacts: data?.hotel_contacts,
              }),
            ),
          );
        } catch (e) {
          console.error(e);
        }
      },
      transformErrorResponse: ({ data, status }) =>
        errorTransform(data, status),
    }),

    setCheckInRules: builder.mutation<
      HotelInfoResponse['accommodation_rules'],
      HotelCheckInRulesQueryParams
    >({
      query: ({ id, body }) => ({
        url: `hotels/${id}/check_in_rules/`,
        method: HTTP_METHODS.PATCH,
        body,
        credentials: 'include',
      }),
      onQueryStarted: async ({ id, body }, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            hotelsApi.util.updateQueryData(
              'getHotelInfo',
              String(id),
              (draft) => {
                return {
                  ...draft,
                  accommodation_rules: {
                    ...body,
                    children_ages: data?.children_ages,
                  },
                };
              },
            ),
          );
        } catch (e) {
          console.error(e);
        }
      },
      invalidatesTags: [
        'RoomInfo',
        'CheckerboardTable',
        'CheckerboardPriceModal',
      ],
    }),

    getHotels: builder.query<
      { hotelsList: TransformedHotelListItem[]; totalItems?: number },
      HotelListQueryParams
    >({
      query: (params) => {
        /** Удаляем falsy параметры из запроса */
        const queryParams = Object.keys(params).reduce(
          (acc, key) =>
            !params[key as keyof HotelListQueryParams]
              ? acc
              : { ...acc, [key]: params[key as keyof HotelListQueryParams] },
          {},
        );

        return {
          url: 'hotels/',
          params: Object.keys(queryParams).length ? queryParams : undefined,
          method: HTTP_METHODS.GET,
          credentials: 'include',
        };
      },
      transformResponse: (data: HotelListResponse) => {
        return {
          hotelsList: data?.results?.map(
            ({
              id,
              hotel_name,
              room_types,
              rates,
              otello_id,
              organization_type,
              address,
              channel_manager,
              channel_manager_hotel_id,
            }) => ({
              id,
              title: hotel_name,
              subtitle: `ID ${id}`,
              address,
              amount: {
                roomTypes: room_types,
                rates: rates,
              },
              otelloId: otello_id,
              organizationType: organization_type ?? null,
              channel_manager,
              channel_manager_hotel_id,
              is_extranet: !!channel_manager?.name,
            }),
          ),
          totalItems: data?.total_items,
        };
      },
      providesTags: ['HotelList'],
    }),

    publishHotel: builder.mutation<
      HotelPublishResponse,
      HotelPublishQueryParams
    >({
      query: ({ id, body }) => ({
        url: `hotels/${id}/publish/`,
        method: HTTP_METHODS.POST,
        credentials: 'include',
        body,
      }),
      transformErrorResponse: ({ data, status }) =>
        errorTransform(data, status),
      invalidatesTags: ['HotelUnpublishedRecords', 'RatesList'],
    }),

    getAllowPublish: builder.query<HotelAllowPublishResponse, string>({
      query: (id) => ({
        url: `hotels/${id}/allow_publish/`,
        method: HTTP_METHODS.GET,
        credentials: 'include',
      }),
      providesTags: ['HotelAllowPublish'],
    }),

    getLegalInfo: builder.mutation<
      HotelSearchLegalInfoResponse,
      HotelSearchLegalInfoQueryParams
    >({
      query: ({ inn, id, source }) => ({
        url: `hotels/${id}/legal_info_search/`,
        method: HTTP_METHODS.GET,
        credentials: 'include',
        params: { inn, source },
      }),
    }),

    getBankInfo: builder.mutation<
      HotelBankInfoResponse,
      HotelBankInfoSearchQueryParams
    >({
      query: ({ bik }) => ({
        url: `hotels/internal/bik_search/`,
        method: HTTP_METHODS.GET,
        credentials: 'include',
        params: { bik },
      }),
    }),

    changeLegalInfo: builder.mutation<
      HotelPublishResponse,
      LegalInfoChangesQueryParams
    >({
      query: ({ hotelId, body }) => ({
        url: `hotels/${hotelId}/legal_info_changes/`,
        method: HTTP_METHODS.POST,
        credentials: 'include',
        body,
      }),
    }),

    getUnpublishedRecords: builder.query<HasUnpublishedRecordsResponse, string>(
      {
        query: (hotelId) => ({
          url: `hotels/${hotelId}/has_unpublished_records/`,
          method: HTTP_METHODS.GET,
          credentials: 'include',
        }),
        providesTags: ['HotelUnpublishedRecords'],
      },
    ),

    getRelatedBranches: builder.mutation<
      HotelRelatedBranchesResponse,
      HotelRelatedBranchQueryParams
    >({
      query: ({ external_id, provider_code, hotel_id }) => ({
        url: `hotels/${hotel_id}/${external_id}/${provider_code}/related_branches/`,
        method: HTTP_METHODS.GET,
        credentials: 'include',
      }),
    }),

    updateRelatedBranches: builder.mutation<
      void,
      HotelUpdateRelatedBranchesQueryParams
    >({
      query: ({ external_id, provider_code, body }) => ({
        url: `hotels/${external_id}/${provider_code}/legal_info_update/`,
        method: HTTP_METHODS.PUT,
        credentials: 'include',
        body,
      }),
      invalidatesTags: ['Hotel', 'HotelList'],
    }),
  }),
});

export const {
  useGetHotelInfoQuery,
  useSetHotelLegalInfoMutation,
  useSetBookingContactsMutation,
  useSetHotelContactsMutation,
  useSetCheckInRulesMutation,
  useGetHotelsQuery,
  usePublishHotelMutation,
  useGetAllowPublishQuery,
  useChangeLegalInfoMutation,
  useGetUnpublishedRecordsQuery,
  useGetLegalInfoMutation,
  useGetBankInfoMutation,
  useGetRelatedBranchesMutation,
  useUpdateRelatedBranchesMutation,
} = hotelsApi;
