import { PaletteOptions } from '@mui/material/styles';

export const themePalette: PaletteOptions = {
  basic: {
    primary: '#11100F',
    secondary: '#848382',
    tertiary: '#D2CECA',
    midnight: {
      100: '#024959',
      90: '#005360',
      80: '#005D65',
      70: '#006768',
      60: '#007167',
    },
    green: {
      100: '#1AA636',
      90: '#28B032',
      80: '#35BA2C',
      70: '#43C325',
      60: '#50CD19',
      30: '#DDECE0',
    },
    gray: {
      100: '#AEA69F',
      90: '#B7B0A9',
      80: '#C0BAB4',
      70: '#C9C4BF',
      60: '#D2CECA',
      50: '#DBD7D4',
      40: '#E4E1DF',
      30: '#EDEBEA',
      20: '#F6F5F4',
    },
    brown: {
      100: '#11100F',
      90: '#211F1E',
      80: '#322F2C',
      70: '#423E3B',
      60: '#534E4A',
      50: '#635E59',
      40: '#746D68',
      30: '#847D76',
      20: '#958C85',
    },
    orange: {
      100: '#FB9700',
      90: '#F6A31B',
      80: '#F2AE2E',
    },
    red: {
      100: '#F43636',
      90: '#FA4544',
      80: '#FF5252',
    },
    white: {
      100: '255, 255, 255',
    },
  },
  accent: {
    success: '#1DB93C',
    warning: '#F6A31B',
    critical: '#FA5939',
  },
  surface: {
    primary: '#FFFFFF',
    secondary: '#F6F5F4',
  },
  bg: {
    primary: '#F6F5F4',
    secondary: '#DEECEF',
    tertiary: '#FFDADA',
  },
  button: {
    primary: {
      main: '#1DB93C',
      hover: '#1BAF39',
      active: '#1AA636',
    },
    surface: {
      main: '#FFFFFF',
      hover: '#F6F5F4',
      active: '#F6F5F4',
    },
    secondary: {
      main: '#EDEBEA',
      hover: '#E4E1DF',
      active: '#DBD7D4',
    },
    tertiary: {
      default: '#211F1E',
      hover: '#322F2C',
    },
    error: {
      main: '#FF5252',
      hover: '#F44C55',
    },
  },
  stroke: {
    default: '#DBD7D4',
    focus: '#024959',
    hover: '#C9C4BF',
    error: '#FF5252',
    input: {
      focus: '#2A6B74',
    },
  },
};
