import {
  CancellationFee,
  CancellationType,
  FoodType,
  PaymentMethod,
  PriceBehaviorEnum,
} from '@otello/api';

export const unknownError = 'Произошла непредвиденная ошибка';

/**
 * Проверка что данная строка является url
 *
 * @example
 *
 * https://2gis.ru/rostov/firm/3378228001621096 - верно
 * https://2gis.ru/rostov/firm/70000001050164465 - верно
 * test.test - ошибка
 * www.ffff - ошибка
 */
export const urlRegExp =
  /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})\S*$/gm;

/**
 * [a-zA-Z0-9] - первая группа. Выражение может начинаться с буквы или числа
 * (\.?[a-zA-Z0-9-_])+ - вторая группа. Выражение может содержать буквы в разном регистре, числа и точку (только одну подряд)
 * @ - обязательный символ
 * [a-zA-Z0-9-_] - третья группа. Разрешены буквы, числа и символ -
 * \. = требуется точка
 * [a-zA-Z]{2,} - последняя группа. Разрешены только буквы, минимум 2 символа
 */
export const emailRegExp =
  /^[a-zA-Z0-9](\.?[a-zA-Z0-9-_])+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/gm;

/**
 * (?=.*?[0-9]) - требует хотя бы одну цифру
 * (?=.*?[a-z]) - требует хотя бы одну строчную букву
 * (?=.*?[A-Z]) - требует хотя бы одну заглавную букву
 * (?=.*?[-+!@$%^&*()_#?"'<>{}[\]\\/`~.,;:|=]) - требует хотя бы один из специальных символов
 *  [0-9a-zA-Z-+!@$%^&*()_#?"'<>{}[\]\\/`~.,;:|=]{12,32} - строка состоит не менее, чем из 12 и не более чем 32 вышеупомянутых символов
 */
export const passwordRegExp =
  /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[-+!@$%^&*()_#?"'<>{}[\]\\/`~.,;:|=])[0-9a-zA-Z-+!@$%^&*()_#?"'<>{}[\]\\/`~.,;:|=]{12,32}$/gm;

/** Телефон с бэкенда всегда приходит в формате +79991112233 **/
export const phoneFromBackendRegExp = /^[+\d]{12}$/mu;

export const phoneRegExp =
  /^(\+7)?[\s-]?\(?[0-9]{3}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$/gmu;

/**
 * (?:|(https?:\/\/)?(|www)[.]?((t|telegram)\.me)\/) - опциональный ввод через ссылку
 *
 * (([a-zA-Z][a-zA-Z0-9_]{5,32}) - первая группа для никнеймов.
 * Начало с буквы (конфискует один символ из второй группы), далее разрешены цифры и _.
 * Минимально 5, максимально 32 символа
 *
 * (\+7\d{10})) - вторая группа для номеров телефона. Начинаются с +7 + 10 цифр
 */
export const telegramRegExp =
  /^(?:|(https?:\/\/)?(|www)[.]?((t|telegram)\.me)\/)(([a-zA-Z][a-zA-Z0-9_]{4,32})|(\+7\d{10}))$/gmu;

/** Валидация для казначейского счета */
export const treasureAccountRegExp = /^(0)(3)\d{18}$/gmu;

/** Определить является ли счет расчетным */
export const checkCheckingAccountRegExp = /^(4)(0)[5678][0-9]*$/gmu;

export const phoneMask = [
  '+',
  '7',
  ' ',
  '(',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  ')',
  ' ',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  ' ',
  /[0-9]/,
  /[0-9]/,
  ' ',
  /[0-9]/,
  /[0-9]/,
];

export const KPPMask = [
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
];

export const INNMask = [
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
];

export const OGRNMask = [
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
];

export const bikRegExp = /^(0)(4)\d{7}$/gmu;
/** Валидация БИК если счет казначейский */
export const bikTreasueRegExp = /^(0)\d{8}$/gmu;
export const BIKMask = [
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
];

/** Валидация для расчетного счета */
export const checkingAccountRegExp = /^(4)(0)[5678]\d{2}(8)(1)(0)\d{12}$/gmu;

/** Валидация для казначейского расчетного счета */
export const checkingTreasureAccountRegExp = /^(4)(0)[12]\d{17}$/gmu;

export const checking_account_mask = [
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
];
export const correspondentAccountRegExp = /^(3)(0)(1)(0)(1)\d{15}$/gmu;

export const correspondent_account_mask = [
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
];

/** Список праздничных дней (на 23.11.2023 прорабатывается вопрос об АПИ) **/
export const holidays = [
  /** 2023 **/
  '01.01.2023',
  '02.01.2023',
  '03.01.2023',
  '04.01.2023',
  '05.01.2023',
  '06.01.2023',
  '07.01.2023',
  '08.01.2023',

  '23.02.2023',
  '24.02.2023',
  '25.02.2023',
  '26.02.2023',

  '08.03.2023',

  '29.04.2023',
  '30.04.2023',

  '01.05.2023',
  '06.05.2023',
  '07.05.2023',
  '08.05.2023',
  '09.05.2023',

  '10.06.2023',
  '11.06.2023',
  '12.06.2023',

  '04.11.2023',
  '05.11.2023',
  '06.11.2023',

  '30.12.2023',
  '31.12.2023',

  /** 2024 **/
  '01.01.2024',
  '02.01.2024',
  '03.01.2024',
  '04.01.2024',
  '05.01.2024',
  '06.01.2024',
  '07.01.2024',
  '08.01.2024',

  '23.02.2024',

  '08.03.2024',

  '29.04.2024',
  '30.04.2024',

  '01.05.2024',
  '09.05.2024',
  '10.05.2024',

  '12.06.2024',

  '04.11.2024',

  '30.12.2024',
  '31.12.2024',
];

export const PaymentTypes: Record<PaymentMethod, string> = {
  [PaymentMethod.hotel]: 'В отеле',
  [PaymentMethod.online]: 'Онлайн',
  [PaymentMethod.hotel_and_online]: 'В отеле и онлайн',
};

export const FoodTypes: Record<FoodType, string> = {
  [FoodType.all_inclusive]: 'Все включено',
  [FoodType.breakfast]: 'Завтрак',
  [FoodType.breakfast_dinner]: 'Завтрак, ужин',
  [FoodType.breakfast_lunch]: 'Завтрак, обед',
  [FoodType.breakfast_lunch_dinner]: 'Завтрак, обед, ужин',
  [FoodType.dinner]: 'Ужин',
  [FoodType.lunch_dinner]: 'Обед, ужин',
  [FoodType.without_food]: 'Без питания',
};

export const CancellationTypes: Record<CancellationType, string> = {
  [CancellationType.first_day_cancel]:
    'Отмена без штрафа за 1 сутки, штраф стоимость первых суток',
  [CancellationType['non-refundable_rate']]: 'Невозвратный тариф',
  [CancellationType.cancel_without_fine]: 'Отмена без штрафа',
  [CancellationType.another_type]: 'Другой тип отмены',
};

export const CancellationFees: Record<CancellationFee, string> = {
  [CancellationFee.entire_price_booking]: '100%',
  [CancellationFee.half_price_booking]: '50%',
  [CancellationFee.one_day_price]: 'в размере 1 суток',
};

export const PriceBehaviorObj: Record<PriceBehaviorEnum, string> = {
  [PriceBehaviorEnum.increase]: 'Цена увеличивается',
  [PriceBehaviorEnum.decrease]: 'Цена уменьшается',
  [PriceBehaviorEnum.notChange]: 'Цена не изменится',
};

export const ALL_DAYS_OF_WEEK = [
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
  'Sun',
];

export const DEPENDENT_TARIFF_INFO =
  'Выберите родительский тариф и цена автоматически рассчитается по выбранной скидке или наценке. Если цена родительского тарифа будет изменена, цена зависимого пересчитается автоматически';

export const DEPENDENT_TARIFF_WARNING =
  'Зависимость тарифа заблокирована. Данный тариф уже используется как родительский для другого тарифа';

export const MONTH_NUMBERS_BY_QUARTER = {
  1: [1, 2, 3],
  2: [4, 5, 6],
  3: [7, 8, 9],
  4: [10, 11, 12],
};

/** Дата последнего обновления в дайджесте новостей */
export const lastDigestDate = '10.06.2024';

/** Дата последнего обновления в дайджесте новостей для экстранета */
export const lastExtranetDigestDate = '06.11.2024';
