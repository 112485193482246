import { FC, useMemo, MouseEventHandler, ReactNode } from 'react';

import {
  Box,
  Drawer as DrawerMUI,
  DrawerProps as DrawerMUIProps,
  PaperProps,
  Typography,
} from '@mui/material';
import { SxProps } from '@mui/system';
import { Chevron, Close } from '@otello/assets';
import { rem } from '@otello/helpers';

import { BackButtonStyled, CloseButtonStyled } from '../Tooltip/Tooltip.style';

interface DrawerProps extends Omit<DrawerMUIProps, 'title'> {
  open: boolean;
  title?: string | ReactNode;
  fullScreen?: boolean;
  onBack?: VoidFunction;
  onClose?: MouseEventHandler<HTMLButtonElement>;
  showCloseIcon?: boolean;
  showBackButton?: boolean;
  closeButtonAttributeValue?: string;
  sxTitle?: SxProps;
  paperProps?: PaperProps & { 'data-cy': string };
}

export const Drawer: FC<DrawerProps> = ({
  open,
  anchor = 'bottom',
  fullScreen = false,
  title,
  onBack,
  onClose,
  showCloseIcon = false,
  showBackButton = true,
  children,
  closeButtonAttributeValue,
  sxTitle,
  paperProps,
  ...rest
}) => {
  const paperPadding: string = useMemo(() => {
    if (title || onBack) {
      return rem(0, 20, 20);
    }

    return rem(22);
  }, [onBack, title]);

  const paperBorderRadius: string | number = useMemo(() => {
    if (fullScreen) {
      return 0;
    }

    switch (anchor) {
      case 'top':
        return rem(0, 0, 20, 20);
      case 'right':
        return rem(20, 0, 0, 20);
      case 'bottom':
        return rem(20, 20, 0, 0);
      case 'left':
        return rem(0, 20, 20, 0);
    }
  }, [anchor, fullScreen]);

  return (
    <DrawerMUI
      {...rest}
      open={open}
      anchor={anchor}
      onClose={onClose}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
          },
        },
      }}
      PaperProps={{
        sx: {
          padding: paperPadding,
          borderRadius: paperBorderRadius,
          width: fullScreen ? '100%' : 'auto',
          ...rest.sx,
        },

        ...paperProps,
      }}
    >
      {(title || onBack) && (
        <Box
          display="flex"
          alignItems="center"
          lineHeight={0}
          width={showCloseIcon ? '86%' : '100%'}
          sx={{
            position: 'sticky',
            top: 0,
            minHeight: rem(60),
            backgroundColor: '#fff',
            zIndex: 2,
            ...sxTitle,
          }}
        >
          {showBackButton && (
            <BackButtonStyled
              data-cy={closeButtonAttributeValue}
              size="small"
              color="secondary"
              onClick={onBack}
              sx={{
                width: rem(40),
                minWidth: rem(40),
                padding: 0,
                mr: rem(10),
                color: 'basic.secondary',
              }}
            >
              <Chevron />
            </BackButtonStyled>
          )}

          {typeof title === 'string' ? (
            <Typography variant="body2" fontWeight={600}>
              {title}
            </Typography>
          ) : (
            title
          )}
        </Box>
      )}

      {showCloseIcon && (
        <CloseButtonStyled
          data-cy={closeButtonAttributeValue}
          size="small"
          color="secondary"
          onClick={onClose}
          sx={{
            width: rem(40),
            minWidth: rem(40),
            padding: 0,
            color: 'basic.secondary',
          }}
        >
          <Close width={rem(24)} height={rem(24)} />
        </CloseButtonStyled>
      )}
      {children}
    </DrawerMUI>
  );
};
