import { PropsWithChildren, useMemo } from 'react';

import { Badge } from '@mui/material';
import { rem } from '@otello/helpers';
import { useAppSelector } from '@otello/store';

import { getIsNeedBadge, getLastDigestDate } from './helpers/helpers';

export const DigestBadge = ({ children }: PropsWithChildren) => {
  const lastView = useAppSelector((state) => state.digest.lastView);
  const lastExtranetView = useAppSelector(
    (state) => state.digest.lastExtranetDigestView,
  );
  const isExtranet = useAppSelector(
    (state) => state?.hotel?.active?.is_extranet,
  );
  const lastDigestDate = getLastDigestDate(Boolean(isExtranet));

  const isShowBadge: boolean = useMemo(() => {
    if (!isExtranet && lastView) {
      /** Если дата последнего просмотра позже даты последнего обновления,
       * то не показываем значок уведомления */
      return getIsNeedBadge(lastView, lastDigestDate);
    }

    /** Для экстранета */
    if (isExtranet && lastExtranetView) {
      return getIsNeedBadge(lastExtranetView, lastDigestDate);
    }

    return true;
  }, [lastView, lastDigestDate, lastExtranetView, isExtranet]);

  return (
    <Badge
      component="div"
      variant={isShowBadge ? 'dot' : undefined}
      sx={{
        '& .MuiBadge-badge': {
          top: rem(6),
          right: rem(6),
          backgroundColor: 'accent.critical',
        },
      }}
    >
      {children}
    </Badge>
  );
};
